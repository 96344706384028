export const initialValues = {
  assessment: {
    question_1: "",
    question_2: "",
    question_3: "",
    question_4: "",
    question_5: "",
    question_6: "",
    question_7: "",
    question_8: "",
    question_9: "",
    question_10: "",
    question_11: "",
    question_12: "",
    question_13: "",
    question_14: "",
  },
  understanding_labels: {
    question_1: "",
    question_2: "",
    question_3: "",
    question_4: "",
    question_5: "",
  },
  reading_information: {
    question_1: "",
    question_2: "",
    question_3: "",
    question_4: "",
    question_5: "",
    question_6: "",
    question_7: "",
    question_8: "",
  },
  line_number_recognition: {
    question_1: "",
    question_2: "",
    question_3: "",
    question_4: "",
    question_5: "",
    question_6: "",
  },
  full_name: "",
  answered_to_best_of_ability: 0,
}
