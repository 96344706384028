import React, { useRef, useState } from "react"
import { Formik } from "formik"
import { Helmet } from "react-helmet"

import { dataURItoBlob } from "../../utils/form-data"
import {
  submitForm,
  handleSubmitResponse,
  jsonToFormData,
} from "../../services"

import { LCard, FieldSet, Col2 } from "../../style/containers"
import WareHouseLabel from "../../assets/images/warehouse-label.png"
import { Table, Sth } from "../../style/tables"

import Layout from "../../components/layout"
import SButton from "../../components/button"
import Signature from "../../components/Signature"
import Form from "../../components/form/FormHelper"
import { FormikTabs, FormikInput, FormikCheckbox } from "../../components/form"
import {
  initialValues,
  validationSchema,
  CalculateResults,
} from "../../components/PinPointOperativeTest"

const PinPointOperativeTest = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const onSubmit = async (values, actions) => {
    values.results = CalculateResults(values)

    if (signatureDrawn) {
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia

      let file

      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
      const blobContent = dataURItoBlob(dataURL)
      const formData = jsonToFormData(values)

      if (isEdge || isIE) {
        file = new Blob([blobContent], "signature.png", {
          type: "image/png",
        })
      } else {
        file = new File([blobContent], "signature.png", { type: "image/png" })
      }

      formData.append("signature", file)
      let res = await submitForm(formData, "")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Operative Test</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <h1>Operative Test</h1>
              <h2>Introduction</h2>
              <ul>
                <li>
                  This is a test designed to find out how well you can
                  understand instructions and interpret information you will
                  come across in the Distribution Centre.
                </li>
                <li>Please make your answers clear.</li>
                <li>Please ensure that you are accurate.</li>
                <li>You have 15 minutes to complete the test.</li>
                <li>
                  There are 3 pages of questions with a total of 33 questions to
                  complete.
                </li>
                <li>
                  To pass the test you need to achieve 25 or more out of 33.
                </li>
                <li>
                  If you are unclear about anything please ask now before the
                  test begins.
                </li>
              </ul>
              <b>Good Luck.</b>
              <FieldSet>
                <h2>Assessment</h2>
                <h4>Question 1</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_1"
                  label="In the number 65, the number 6 is worth?"
                  tabs={[
                    { name: "6", label: "6" },
                    { name: "60", label: "60" },
                    { name: "65", label: "65" },
                    { name: "66", label: "66" },
                  ]}
                />
                <h4>Question 2</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_2"
                  label="What value does the 3 have in 2380?"
                  tabs={[
                    { name: "3", label: "3" },
                    { name: "30", label: "30" },
                    { name: "300", label: "300" },
                    { name: "3000", label: "3000" },
                  ]}
                />
                <h4>Question 3</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_3"
                  label="How many centimetres are in a metre?"
                  tabs={[
                    { name: "1", label: "1" },
                    { name: "10", label: "10" },
                    { name: "100", label: "100" },
                    { name: "1000", label: "1000" },
                  ]}
                />
                <h4>Question 4</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_4"
                  label="13 + 24 = ?"
                  tabs={[
                    { name: "27", label: "27" },
                    { name: "36", label: "36" },
                    { name: "37", label: "37" },
                    { name: "48", label: "48" },
                  ]}
                />
                <h4>Question 5</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_5"
                  label="167 + 28 = ?"
                  tabs={[
                    { name: "139", label: "139" },
                    { name: "185", label: "185" },
                    { name: "187", label: "187" },
                    { name: "195", label: "195" },
                  ]}
                />
                <h4>Question 6</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_6"
                  label="You buy a present for £5.99 and a card for £1.25. What is the total cost?"
                  tabs={[
                    { name: "£6.28", label: "£6.28" },
                    { name: "£7.24", label: "£7.24" },
                    { name: "£7.29", label: "£7.29" },
                    { name: "£8.14", label: "£8.14" },
                  ]}
                />
                <h4>Question 7</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_7"
                  label="You have £45 at the start of a shopping trip
                  and £23 at the end. How much have you spent?"
                  tabs={[
                    { name: "£22", label: "£22" },
                    { name: "£28", label: "£28" },
                    { name: "£32", label: "£32" },
                    { name: "£68", label: "£68" },
                  ]}
                />
                <h4>Question 8</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_8"
                  label="234 - 148 = ?"
                  tabs={[
                    { name: "86", label: "86" },
                    { name: "114", label: "114" },
                    { name: "186", label: "186" },
                    { name: "382", label: "382" },
                  ]}
                />
                <h4>Question 9</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_9"
                  label="7.86 - 2.69 = ?"
                  tabs={[
                    { name: "£5.17", label: "£5.17" },
                    { name: "£5.23", label: "£5.23" },
                    { name: "£5.27", label: "£5.27" },
                    { name: "£10.55", label: "£10.55" },
                  ]}
                />
                <h4>Question 10</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_10"
                  label="7 x ? = 56"
                  tabs={[
                    { name: "6", label: "6" },
                    { name: "7", label: "7" },
                    { name: "8", label: "8" },
                    { name: "9", label: "9" },
                  ]}
                />
                <h4>Question 11</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_11"
                  label="You buy 3 pens at 24p each. What is the total cost?"
                  tabs={[
                    { name: "48p", label: "48p" },
                    { name: "62p", label: "62p" },
                    { name: "66p", label: "66p" },
                    { name: "72p", label: "72p" },
                  ]}
                />
                <h4>Question 12</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_12"
                  label="12 x 20 = ?"
                  tabs={[
                    { name: "24", label: "24" },
                    { name: "120", label: "120" },
                    { name: "124", label: "124" },
                    { name: "240", label: "240" },
                  ]}
                />
                <h4>Question 13</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_13"
                  label="48 ÷ 3 = ?"
                  tabs={[
                    { name: "12", label: "12" },
                    { name: "14", label: "14" },
                    { name: "16", label: "16" },
                    { name: "18", label: "18" },
                  ]}
                />
                <h4>Question 14</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="assessment.question_14"
                  label="48 ÷ ? = 0.48 ?"
                  tabs={[
                    { name: "1", label: "1" },
                    { name: "10", label: "10" },
                    { name: "48", label: "48" },
                    { name: "100", label: "100" },
                  ]}
                />
              </FieldSet>
              <FieldSet>
                <h2>Understanding Labels</h2>
                <p>
                  Below is a copy of a box end label for goods received into the
                  warehouse. Please answer the questions below. All answers can
                  be found in the label.
                </p>
                <img src={WareHouseLabel} alt="Warehouse Label Example" />
                <br />
                <p>
                  Example: What is the Carton Number?
                  <br />
                  <b>Answer: 54</b>
                </p>
                <br />
                <Col2>
                  <FormikInput
                    name="understanding_labels.question_1"
                    type="text"
                    label="1. What is the M.D.A. Number?"
                  />
                  <FormikInput
                    name="understanding_labels.question_2"
                    type="text"
                    label="2. What is the carton quantity?"
                  />
                  <FormikInput
                    name="understanding_labels.question_3"
                    type="text"
                    label="3. What is the size/fit of the product?"
                  />
                  <FormikInput
                    name="understanding_labels.question_4"
                    type="text"
                    label="4. What is the Purchase Order Number?"
                  />
                  <FormikInput
                    name="understanding_labels.question_5"
                    type="text"
                    label="5. What is the Line Number?"
                  />
                </Col2>
              </FieldSet>
              <FieldSet>
                <h2>Reading Information Accurately</h2>
                <p>
                  Below are two columns with information in them. Please state
                  whether the information is the same or different.
                </p>
                <Table>
                  <tbody>
                    <tr>
                      <Sth>Information A</Sth>
                      <Sth>Information B</Sth>
                      <Sth>Information the same?</Sth>
                    </tr>
                    <tr>
                      <td>
                        <b>16A30DNAV</b>
                      </td>
                      <td>
                        <b>16A30DMAV</b>
                      </td>
                      <td>
                        <FormikTabs
                          setFieldValue={setFieldValue}
                          name="reading_information.question_1"
                          label=""
                          tabs={[
                            { name: "Yes", label: "Yes" },
                            { name: "No", label: "No" },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>TS 0124</b>
                      </td>
                      <td>
                        <b>TM 0124</b>
                      </td>
                      <td>
                        <FormikTabs
                          setFieldValue={setFieldValue}
                          name="reading_information.question_2"
                          label=""
                          tabs={[
                            { name: "Yes", label: "Yes" },
                            { name: "No", label: "No" },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>10K07ERED</b>
                      </td>
                      <td>
                        <b>10K07ERED</b>
                      </td>
                      <td>
                        <FormikTabs
                          setFieldValue={setFieldValue}
                          name="reading_information.question_3"
                          label=""
                          tabs={[
                            { name: "Yes", label: "Yes" },
                            { name: "No", label: "No" },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>15.10.95</b>
                      </td>
                      <td>
                        <b>15.10.96</b>
                      </td>
                      <td>
                        <FormikTabs
                          setFieldValue={setFieldValue}
                          name="reading_information.question_4"
                          label=""
                          tabs={[
                            { name: "Yes", label: "Yes" },
                            { name: "No", label: "No" },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Start Time 18.57</b>
                      </td>
                      <td>
                        <b>Start Time 8.57</b>
                      </td>
                      <td>
                        <FormikTabs
                          setFieldValue={setFieldValue}
                          name="reading_information.question_5"
                          label=""
                          tabs={[
                            { name: "Yes", label: "Yes" },
                            { name: "No", label: "No" },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>0163 Leeds</b>
                      </td>
                      <td>
                        <b>0163 Leeds</b>
                      </td>
                      <td>
                        <FormikTabs
                          setFieldValue={setFieldValue}
                          name="reading_information.question_6"
                          label=""
                          tabs={[
                            { name: "Yes", label: "Yes" },
                            { name: "No", label: "No" },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>30B14BRED</b>
                      </td>
                      <td>
                        <b>30B14BRED</b>
                      </td>
                      <td>
                        <FormikTabs
                          setFieldValue={setFieldValue}
                          name="reading_information.question_7"
                          label=""
                          tabs={[
                            { name: "Yes", label: "Yes" },
                            { name: "No", label: "No" },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>36B12ESTN</b>
                      </td>
                      <td>
                        <b>36B12ESTN</b>
                      </td>
                      <td>
                        <FormikTabs
                          setFieldValue={setFieldValue}
                          name="reading_information.question_8"
                          label=""
                          tabs={[
                            { name: "Yes", label: "Yes" },
                            { name: "No", label: "No" },
                          ]}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </FieldSet>
              <FieldSet>
                <h2>Line Number Recognition</h2>
                <p>
                  Below are questions with a line number in them. Please
                  accurately type the number from the question into the box
                  below it. Please note, these numbers CANNOT BE COPY AND PASTED
                </p>
                <Col2>
                  <FormikInput
                    name="line_number_recognition.question_1"
                    type="text"
                    label="1. 26R01EDEN"
                  />
                  <FormikInput
                    name="line_number_recognition.question_2"
                    type="text"
                    label="2. 16A30DNAV"
                  />
                  <FormikInput
                    name="line_number_recognition.question_3"
                    type="text"
                    label="3. 16E02EBLE"
                  />
                  <FormikInput
                    name="line_number_recognition.question_4"
                    type="text"
                    label="4. 16A48DBLK"
                  />
                  <FormikInput
                    name="line_number_recognition.question_5"
                    type="text"
                    label="5. 30T03EBLU"
                  />
                  <FormikInput
                    name="line_number_recognition.question_6"
                    type="text"
                    label="6. 24A02EKHA"
                  />
                </Col2>
              </FieldSet>
              <h2>Completion</h2>
              <FormikInput
                name="full_name"
                type="text"
                label="Your full name"
              />
              <FormikCheckbox
                bold={true}
                label="I have answered the above questions to the best of my ability and without additional help"
                name="answered_to_best_of_ability"
              />
              <Signature
                signatureDrawn={signatureDrawn}
                setSignatureDrawn={setSignatureDrawn}
                sigCanvas={sigCanvas}
              />
              {errorText
                ? errorText.map(e => (
                    <Error style={{ marginTop: "1rem" }} key={e}>
                      {e}
                    </Error>
                  ))
                : null}
              <SButton type="submit" disabled={isSubmitting}>
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default PinPointOperativeTest
