export const CalculateResults = values => {
  let results = {
    assessment: {},
    understanding_labels: {},
    reading_information: {},
    line_number_recognition: {},
  }

  let score = 0

  const MarkQuestion = (section, question, correctAnswer) => {
    if (values[section][question] === correctAnswer) {
      results[section][question] = "Correct"
      score++
    } else {
      results[section][question] = "Incorrect"
    }
  }
  // Numberical
  MarkQuestion("assessment", "question_1", "60")
  MarkQuestion("assessment", "question_2", "300")
  MarkQuestion("assessment", "question_3", "100")
  MarkQuestion("assessment", "question_4", "37")
  MarkQuestion("assessment", "question_5", "195")
  MarkQuestion("assessment", "question_6", "£7.24")
  MarkQuestion("assessment", "question_7", "£22")
  MarkQuestion("assessment", "question_8", "86")
  MarkQuestion("assessment", "question_9", "£5.17")
  MarkQuestion("assessment", "question_10", "8")
  MarkQuestion("assessment", "question_11", "72p")
  MarkQuestion("assessment", "question_12", "240")
  MarkQuestion("assessment", "question_13", "16")
  MarkQuestion("assessment", "question_14", "100")
  // Understanding Labels
  MarkQuestion("understanding_labels", "question_1", "671094632")
  MarkQuestion("understanding_labels", "question_2", "40")
  MarkQuestion("understanding_labels", "question_3", "10")
  MarkQuestion("understanding_labels", "question_4", "BJ5682461")
  MarkQuestion("understanding_labels", "question_5", "04A22BBLK")
  // Reading Information Accurately
  MarkQuestion("reading_information", "question_1", "No")
  MarkQuestion("reading_information", "question_2", "No")
  MarkQuestion("reading_information", "question_3", "Yes")
  MarkQuestion("reading_information", "question_4", "No")
  MarkQuestion("reading_information", "question_5", "No")
  MarkQuestion("reading_information", "question_6", "Yes")
  MarkQuestion("reading_information", "question_7", "Yes")
  MarkQuestion("reading_information", "question_8", "Yes")
  // Line Number Recognition
  MarkQuestion("line_number_recognition", "question_1", "26R01EDEN")
  MarkQuestion("line_number_recognition", "question_2", "16A30DNAV")
  MarkQuestion("line_number_recognition", "question_3", "16E02EBLE")
  MarkQuestion("line_number_recognition", "question_4", "16A48DBLK")
  MarkQuestion("line_number_recognition", "question_5", "30T03EBLU")
  MarkQuestion("line_number_recognition", "question_6", "24A02EKHA")

  results.score = score + "/33" + (score > 25 ? " (Passed)" : " (Failed)")

  return results
}
